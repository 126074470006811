import { useState, useEffect } from 'react'
import { Navigation, Pagination, Autoplay, Scrollbar } from 'swiper/modules'
import { SwiperSlide, Swiper } from 'swiper/react'
import { Button, Container, Row, Spinner } from 'react-bootstrap';
import cornIcon from '../../assets/cornIcon.png'
import sorghumIcon from '../../assets/sorghumIcon.png'
import soybeanIcon from '../../assets/soybeanIcon.png'
import BidCard from '../BidCard';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import 'swiper/css';
import { Bid, BidService } from 'uai-graos-sdk';
import EmptyCard from '../EmptyCard';

export default function BidSlider() {
    const [side, setSide] = useState<'C' | 'V' | undefined>('C')
    const [corn, setCorn] = useState<Bid.PagedBidSite>()
    const [sorghum, setSorghum] = useState<Bid.PagedBidSite>()
    const [soybean, setSoybean] = useState<Bid.PagedBidSite>()
    const [loading, setLoading] = useState(true)

    function getBids(side?: 'C' | 'V') {
        setLoading(true)
        setSide(side)
        BidService.getBidsSite({ product: 'Sorgo', size: 3, side }).then(setSorghum)
        BidService.getBidsSite({ product: 'Milho', size: 3, side }).then(setCorn)
        BidService.getBidsSite({ product: 'Soja', size: 3, side }).then(setSoybean)
        setLoading(false)
    }
    useEffect(() => {
        getBids('C');
    }, [])

    return (
        <div className='my-5'>
            <h2 className='text-center mt-5'>Acompanhe as ofertas aqui:</h2>
            <Container className='bg-success-subtle rounded border border-success'>
                <div className="btn-group bg-white mt-3" role="group">
                    <Button onClick={() => getBids('C')} variant="outline-success" active={side === 'C'}>Ofertas de Compra</Button>
                    <Button onClick={() => getBids('V')} variant="outline-success" active={side === 'V'}>Ofertas de Venda</Button>
                </div>
                {loading ? <Spinner /> :
                    (
                        <Swiper
                            modules={[Navigation, Pagination, Autoplay, Scrollbar]}
                            pagination={{ clickable: true }}
                            autoplay={{ delay: 8000 }}
                            scrollbar={{ draggable: true }}
                            slidesPerView={1}
                            loop
                        >
                            <SwiperSlide>
                                <h3 className='my-4 text-uppercase'>Últimas ofertas de Milho</h3>
                                <Row sm={1} md={4}>
                                    {corn?.content?.map((item, index) => (
                                        <BidCard
                                            key={index}
                                            image={cornIcon}
                                            bid={item}
                                        />
                                    ))}
                                    <EmptyCard product='Milho' side={side} />
                                </Row>
                            </SwiperSlide>
                            <SwiperSlide>
                                <h3 className='my-4 text-uppercase'>Últimas ofertas de Soja</h3>
                                <Row sm={1} md={4}>
                                    {soybean?.content?.map((item, index) => (
                                        <BidCard
                                            key={index}
                                            image={soybeanIcon}
                                            bid={item}
                                        />
                                    ))}
                                    <EmptyCard product='Soja' side={side} />
                                </Row>
                            </SwiperSlide>
                            <SwiperSlide>
                                <h3 className='my-4 text-uppercase'>Últimas ofertas de Sorgo</h3>
                                <Row sm={1} md={4}>
                                    {sorghum?.content?.map((item, index) => (
                                        <BidCard
                                            key={index}
                                            image={sorghumIcon}
                                            bid={item}
                                        />
                                    ))}
                                    <EmptyCard product='Sorgo' side={side} />
                                </Row>
                            </SwiperSlide>
                        </Swiper>
                    )
                }

            </Container>
        </div>
    )
}