import DefaultLayout from '../../layouts/DefaultLayout'
import { Link } from 'react-router-dom'
import './styles.css'
import ProductCard from '../../components/ProductCard'
import CarouselSlider from '../../components/CarouselSlider'
import useNews from '../../core/hooks/useNews'
import { useEffect, useState } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import NewsCard from '../../components/NewsCard'
import { FaChevronRight } from 'react-icons/fa'
import PartnersSlider from '../../components/PartnersSlider'
import MarketData from '../../components/MarketDataCard'
import BidSlider from '../../components/BidSlider'

export default function Home() {

  const { newsHomePage, fetchNewsHomePage } = useNews()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    fetchNewsHomePage();
    setLoading(false)
  }, [fetchNewsHomePage])

  return (
    <DefaultLayout>
      <BidSlider />
      <CarouselSlider />
      {loading ? <Spinner animation="border" role="status" variant="success" className='mt-5'>
        <span className="visually-hidden">Carregando notícias...</span>
      </Spinner> :
        newsHomePage && (
          
          <div>
          <Link to='/news' className='text-dark'>
            <p className='text-center text-uppercase text-success text-uppercase fs-2 mt-5 fw-bold'>
              Notícias <FaChevronRight className='mb-2' />
            </p>
            <p className='text-center fw-medium fs-5'>
              Mantenha-se atualizado sobre as principais notícias do setor agrícola seguindo a Uai Grãos em todas as redes sociais!
            </p>
          </Link>
          <Row xs={1} sm={3} className={`my-5 ${loading ? 'd-flex justify-content-center' : ''}`} >
            {newsHomePage?.data?.map((item) =>
              <Col key={item.id}>
                <NewsCard
                  media_type={item.media_type}
                  caption={item.caption}
                  id={item.id}
                  key={item.id}
                  media_url={item.media_url}
                  permalink={item.permalink}
                  timestamp={item.timestamp}
                  username={item.username}
                  thumbnail_url={item.thumbnail_url}
                />
              </Col>
            )}
          </Row>
          </div>
        )}
      <hr className='blur-line'/>
      <PartnersSlider />
      <hr className='blur-line'/>
      <MarketData />
      <hr className='blur-line'/>
      <Link to='/news' className='text-dark'>
        <p className='text-center text-uppercase text-success text-uppercase fs-2 mt-5 fw-bold'>
          Produtos <FaChevronRight className='mb-2' />
        </p>
        <p className='text-center fw-medium fs-5'>
          Fique por dentro das últimas novidades dos produtos que oferecemos serviço
        </p>
      </Link>
      <ProductCard />
    </DefaultLayout>
  )
}
