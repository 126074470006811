import React, { useEffect } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import moment from 'moment'
import useMarketData from '../../core/hooks/useMarketData'
import { FaArrowAltCircleLeft, FaArrowCircleDown, FaArrowCircleUp } from 'react-icons/fa'

export default function MarketData() {
    const { fetchMarketData, marketData } = useMarketData()
    useEffect(() => {
        fetchMarketData()
    }, [fetchMarketData])

    return (
        <div>
            <p className='text-center text-uppercase text-success text-uppercase fs-2 mt-5 fw-bold'>
                Dados de Mercado
            </p>
            <p className='text-center fw-medium fs-5'>
                Acompanhe as cotações do mercado financeiro por aqui:
            </p>
            <Row className='my-5' sm={1} md={3}>
                {marketData?.map((item) => (
                    <Col className='mt-5 d-flex justify-content-center'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Header className='text-dark fs-5 fw-bold'>{
                                item.marketDataId?.symbol === 'Leite litro' ? 'Leite (litro)' :
                                    item.marketDataId?.symbol === 'Boi Gordo @' ? 'Boi Gordo (arroba)' :
                                        item.marketDataId?.symbol === 'Dólar Spot Comercial' ? 'Dólar' :
                                            item.marketDataId?.symbol
                            }</Card.Header>
                            <Card.Body>
                                <Card.Subtitle className={`mb-2 text-${item.variation! > 0 ? 'success' : item.variation! < 0 ? 'danger' : 'dark'}`}>R$ {item.lastPrice?.toString().replace('.', ',')} | {item.variation?.toFixed(2)}% {item.variation! > 0 ? <FaArrowCircleUp style={{ marginBottom: 4 }} /> : item.variation! < 0 ? <FaArrowCircleDown style={{ marginBottom: 4 }} /> : <FaArrowAltCircleLeft style={{ marginBottom: 4 }} />} </Card.Subtitle>
                                <Card.Subtitle className={"mb-2 text-muted"}>Ultima atualização: {moment(item.marketDataId?.date).format('DD/MM/YYYY')}</Card.Subtitle>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    )
}
