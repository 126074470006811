import { useState, useEffect } from 'react'
import { Button, Col, Container, Dropdown, Form, Pagination, Row } from 'react-bootstrap'
import { Bid, BidService } from 'uai-graos-sdk'
import DefaultLayout from '../../layouts/DefaultLayout'
import BidCard from '../../components/BidCard'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './styles.css'
import { useParams } from 'react-router-dom'

export default function Bids() {
  
  const {product, side} = useParams()

 
  const [city, setCity] = useState<string>('')
  const [state] = useState<string>('')
  const [bids, setBids] = useState<Bid.PagedBidSite>()
  const [pagination, setPagination] = useState<Bid.Query>({
    page: 0,
    size: 50,
    side,
    city: '',
    product,
    state: '',
  })
  const cornIcon = 'https://i.imgur.com/k7IKkEz.png';
  const soybeanIcon = 'https://i.imgur.com/cuMgtdL.png';
  const sorghumIcon = 'https://i.imgur.com/1Gpgoph.png'

  useEffect(() => {
    BidService.getBidsSite(pagination).then(setBids)
  }, [pagination])

  const handlePageClick = (number: any) => {
    setPagination({ ...pagination, page: number - 1 })
  }

  let active = 1;
  let items = [];
  for (let number = 1; number <= bids?.totalPages!; number++) {
    items.push(
      <Pagination.Item className={`rounded`} onClick={() => handlePageClick(number)} key={number} active={number === active}>
        {number}
      </Pagination.Item>,
    );
  }

  return (
    <DefaultLayout>
      <h2 className='text-center my-4'>Acompanhe as ofertas aqui:</h2>
      <Container className='bg-success-subtle rounded pt-3'>
        <Row sm={1} md={3}>
          <Col>
            <Dropdown className='bg-white rounded'>
              <Dropdown.Toggle style={{ width: '100%' }} size='lg' variant="outline-success" id="dropdown-autoclose-false">
                {!pagination.product ? 'Produto' : pagination.product}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className={`bg-${pagination.product === 'Milho' ? 'success' : 'white'}`} active={pagination.product === 'Milho'} onClick={() => setPagination({...pagination, product:'Milho'})}>Milho</Dropdown.Item>
                <Dropdown.Item className={`bg-${pagination.product === 'Soja' ? 'success' : 'white'}`} active={pagination.product === 'Soja'} onClick={() => setPagination({...pagination, product:'Soja'})}>Soja</Dropdown.Item>
                <Dropdown.Item className={`bg-${pagination.product === 'Sorgo' ? 'success' : 'white'}`} active={pagination.product === 'Sorgo'} onClick={() => setPagination({...pagination, product:'Sorgo'})}>Sorgo</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col sm={3}>
            <Form.Control type="text" value={city} onChange={(e) => setCity(e.target.value)} size='lg' className="form-control input-lg" placeholder="Cidade" aria-label="Cidade" aria-describedby="basic-addon1" />
          </Col>
          <Col sm={3}>
            <button className="btn btn-success btn-lg" onClick={() => setPagination({ ...pagination, city, state, product })} type="button">Pesquisar</button>
          </Col>
        </Row>
        <div className="btn-group bg-white mt-3" role="group">
          <Button onClick={() => setPagination({ ...pagination, side: 'C' })} variant="outline-success" active={pagination.side === 'C'}>Ofertas de Compra</Button>
          <Button onClick={() => setPagination({ ...pagination, side: 'V' })} variant="outline-success" active={pagination.side === 'V'}>Ofertas de Venda</Button>
        </div>
        <Row md={3} className='mb-4'>
          {
            bids?.content?.map((item, index) => (
              <BidCard
                key={index}
                image={item.product === 'Milho' ? cornIcon : item.product === 'Sorgo' ? sorghumIcon : soybeanIcon}
                bid={item}
              />
            ))
          }
        </Row>
        <Row>
          <Pagination className='justify-content-center'>
            {items}
          </Pagination>
        </Row>
      </Container>
    </DefaultLayout>
  )
}