import { useCallback, useState } from "react";
import { InstagramPostsService } from "../../services/InstagramPosts.service";
import { PostsPaginated } from "../../@types/Posts";

export default function useNews() {
    const [news, setNews] = useState<PostsPaginated>()
    const [newsHomePage, setNewsHomePage] = useState<PostsPaginated>()

    const fetchNews = useCallback(async () => {
        await InstagramPostsService.getPosts(24).then(setNews)
    }, [])
    const fetchNewsHomePage = useCallback(async () => {
        await InstagramPostsService.getPosts(3).then(setNewsHomePage)
    }, [])

    return {
        news,
        newsHomePage,
        fetchNews,
        fetchNewsHomePage
    }
}
