import HttpService from './httpService';
import { PostsPaginated } from '../@types/Posts';

const token = process.env.REACT_APP_INSTAGRAM_API_TOKEN
const userId = process.env.REACT_APP_INSTAGRAM_USER_ID

class InstagramPostsService extends HttpService {
    static getPosts(limit?: number) {
        return this.Http.get<PostsPaginated>(`https://graph.instagram.com/${userId}/media`,
            {
                params: {
                    access_token: token,
                    fields: 'media_url,permalink,thumbnail_url,timestamp,caption,username,media_type',
                    limit: limit
                }
            }
        )
            .then(this.getData)
    }
}

export { InstagramPostsService };