import React, { useEffect, useState } from 'react'
import { FaArrowAltCircleLeft, FaArrowCircleDown, FaArrowCircleUp } from 'react-icons/fa'
import './styles.css'
import Header from '../Header'
import useMarketData from '../../core/hooks/useMarketData'

export default function HeaderTicker() {
    const [loading, setLoading] = useState<boolean>(true)
    const { fetchMarketData, marketData } = useMarketData()
    useEffect(() => {
        fetchMarketData()
        setLoading(false)
    }, [fetchMarketData])

    if (loading) return <Header />

    return (
        <div className='header-container'>
            <div className='ticker-container'>
                <div className="news-container bg-body-tertiary">
                    <div className="title">
                        Cotações
                    </div>
                    <ul>
                        {marketData?.map((item) => (
                            <li key={item.marketDataId?.symbol} className={`text-${item.variation! > 0 ? 'success' : item.variation! < 0 ? 'danger' : 'dark'}`}>
                                {item.marketDataId?.symbol === 'Leite litro' ? 'Leite (litro)' :
                                    item.marketDataId?.symbol === 'Boi Gordo @' ? 'Boi Gordo (arroba)' :
                                        item.marketDataId?.symbol === 'Dólar Spot Comercial' ? 'Dólar' :
                                            item.marketDataId?.symbol
                                } | R$ {
                                    item.lastPrice?.toFixed(2).toString().replace('.', ',')
                                } | {item.variation! > 0 ? <FaArrowCircleUp style={{ marginBottom: 4 }} /> : item.variation! < 0 ? <FaArrowCircleDown style={{ marginBottom: 4 }} /> : <FaArrowAltCircleLeft style={{ marginBottom: 4 }} />} {item.variation!.toFixed(2)}%
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <Header />
        </div>

    )
}
