import React from 'react'
import { Col } from 'react-bootstrap'
import { Bid } from 'uai-graos-sdk'
import './styles.css'
import { Link } from 'react-router-dom'

type BidProps = {
    bid: Bid.BidSite;
    image: string
}


export default function BidCard({bid, image} : BidProps) {
    return (
        <Col sm={1} md={3}>
            <Link to={`https://wa.me//553496423233?text=Olá! Tenho interesse na oferta de ${bid.side === "C" ? 'Compra' : 'Venda'} de ${bid.product}, com o preço de R$${bid.price} em ${bid?.city} - ${bid?.state}`} target='_blank'>
            <div id="container">
                <div className="product-details text-dark">
                    <h1 className={`product-text-${bid?.product}`}><img src={image} style={{ width: 35, marginBottom: 5 }} alt={bid?.product} /> {bid?.product} - R$ {bid.price?.toFixed(2).toString().replace('.', ',')}</h1>
                    <p>Quantidade: {bid?.quantity}</p>
                    <p>Local:<br /> {bid?.city} - {bid?.state}</p>
                    <p>Frete:<br />{bid?.freight ? bid?.freight : 'Á combinar'}</p>
                </div>
                <div className="product-overlay">
                    <p>Mais informações</p>
                </div>
            </div>
            </Link>
        </Col>
    )
}


