import { useCallback, useState } from "react";
import { MarketData, MarketDataService } from "uai-graos-sdk";

export default function useMarketData() {
    const [marketData, setMarketData] = useState<MarketData.MarketData[]>()

    const fetchMarketData = useCallback(async () => {
        await MarketDataService.getLastCotation()
            .then(setMarketData)
    }, [])
    
    return {
        marketData,
        fetchMarketData
    }
}
