import { Link } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';
import { partnersData } from '../../pages/Partners';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import 'swiper/css';
import './styles.css'
import { Col, Row } from 'react-bootstrap';

export default function PartnersSlider() {

    return (
        <div className="wrapper">
            <div>
                <Link to='/partners'>
                    <p className='text-center text-success text-uppercase fs-3 fw-bold'>Parceiros & Clientes <FaChevronRight className='mb-2' /></p>
                </Link>
                <p className='text-center fw-medium fs-5'>Empresas parceiras que depositam total confiança em nossa atuação</p>
            </div>
            {/* <Swiper
                breakpoints={{
                    576: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                }}
                modules={[Navigation, Pagination, Autoplay, Scrollbar]} 
                pagination={{ clickable: true }}
                autoplay={{ delay: 2000 }}
                scrollbar={{ draggable: true }}
                className='swiper-container'
            > */}
            <Row sm={1} md={2}>
                {partnersData.map((item) => (
                    // <SwiperSlide className='mt-3' key={item.key}>
                    <Col className='my-1 d-flex justify-content-center' key={item.key}>
                        <Link to={item.href} target='_blank' rel="noreferrer">
                            <img src={item.image} className='img-thumbnail' alt={item.alt} style={{ width: 510, height: 510 }} />
                        </Link>
                    </Col>
                    // </SwiperSlide>
                ))}
            </Row>
            {/* </Swiper> */}
        </div>
    );
}