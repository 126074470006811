import './App.css';
import Routes from './routes';
import ReactGA from 'react-ga4';
const TRACKING_ID = "G-B3W2BD2BV2"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

ReactGA.send({
  hitType: 'pageview',
  page: window.location.pathname
})

function App() {
  return <Routes />
}

export default App;
