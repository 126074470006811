import React from "react";
import { Col } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function EmptyCard({product, side}:any) {
    return (
        <Link to={`/bids/${product}/${side}`}>
            <Col>
                <div id="container">
                    <div className="product-details">
                        <h3 className="text-dark">Ver mais ofertas</h3>
                        <h1 className="text-dark"><FaArrowRight /></h1>
                    </div>
                </div>
            </Col>
        </Link>

    )
}