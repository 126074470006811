import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { ProductCard as ProductCardProps } from '../../@types/ProductCard';
import sorgo from '../../assets/sorgo.jpg'
import soja from '../../assets/soja.png'
import milho from '../../assets/milho.png'

export default function ProductCard() {
  const products: ProductCardProps[] = [
    {
      key: '1',
      title: 'Milho',
      description: 'O milho é uma das principais culturas agrícolas do Brasil. Somente para a safra de 2023, o Instituto Brasileiro de Geografia e Estatística (IBGE) espera um aumento de 11,2% na produção nacional dessa cultura. Isso totaliza uma produção recorde de 122,5 milhões de toneladas.',
      alt: 'Milho',
      image: milho,
    },
    {
      key: '2',
      title: 'Sorgo',
      description: 'Em 2023 o teve um aumento de 22,3% na área plantada e a previsão de uma produção 34% superior à obtida em 2022, o cultivo de sorgo granífero é a atividade agrícola que mais avança proporcionalmente, no Brasil, considerando a safra passada e a atual.',
      alt: 'Sorgo',
      image: sorgo,
    },
    {
      key: '3',
      title: 'Soja',
      description: 'A safra de soja do Brasil 2023/24 está estimada em recorde de 162,8 milhões de toneladas, alta de 5,3% na comparação com a temporada anterior, com uma recuperação esperada na colheita do Rio Grande do Sul após a seca no ciclo passado, informou nesta segunda-feira a empresa de consultoria e gestão de riscos hEDGEpoint Global Markets.',
      alt: 'Soja',
      image: soja,
    },
  ]
  return (
    <Container>
      <Row>
        {products.map((item) => (
          <Col className='d-flex justify-content-around my-5' key={item.key}>
          <div className="card" style={{ width: "18rem" }}>
              <img src={item.image} className="card-img-top" alt={item.alt} />
              <div className="card-body">
                  <h5 className="card-title">{item.title}</h5>
                  <p className="text-dark">{item.description}</p>
              </div>
          </div>
      </Col>
        ))}
      </Row>
    </Container>
  )
}

