import { FaHome, FaEnvelope, FaPhone } from "react-icons/fa";
import { Contact as ContactProps } from "../../@types/Contact";
import { HeaderItems as MenuProps } from "../../@types/HeaderItems";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './styles.css'
import SocialMediaIcons from "../SocialMediaIcons";
import { Link } from "react-router-dom";


export default function Footer() {
    const contact: ContactProps[] = [
        {
            key: '1',
            icon: FaHome,
            text: 'Uberlândia-MG - 38406-634',
            href: 'https://maps.app.goo.gl/zjy4BTC9t7tD4FSi8'
        },
        {
            key: '2',
            icon: FaPhone,
            text: '(34) 99642-3233',
            href: 'whatsapp://send?text=Olá estou tendo algumas dúvidas, poderia me ajudar?&phone=5534996423233'
        },
        {
            key: '3',
            icon: FaEnvelope,
            text: 'contato@uaigraos.com.br',
            href: 'mailto:contato@uaigraos.com.br'
        }
    ]

    const menuItems: MenuProps[] = [
        {
            key: '1',
            href: '/',
            title: 'Home'
        },
        {
            key: '2',
            href: '/news',
            title: 'Notícias'
        },
        {
            key: '3',
            href: '/partners',
            title: 'Parceiros/Clientes'
        },
        {
            key: '4',
            href: '/contact',
            title: 'Contato'
        },
        {
            key: '5',
            href: '/about',
            title: 'Quem Somos'
        }
    ]

    const currentYear = new Date().getFullYear();

    return (
        <div>
            <footer className="bg-white text-dark pt-5 pb-4 p-3 bg-body-tertiary">
                <Container className="tex-md-left">
                    <Row className="row text-md-left ">
                        <Col md='3' lg='3' xl='3' className="mx-auto mt-3">
                            <h5 className="text-uppercase text-success">UAI GRÃOS</h5>
                            <p>Startup Mineira focada na negociação de grãos com a facilidade de realizar um Barter com a Industria. Temos uma estrutura completa de consultoria para atender a sua colheita até a chegada da Industria.</p>
                        </Col>
                        <Col md='3' lg='2' xl='2' className="mx-auto mt-3">
                            <h5 className="text-uppercase mb-4 font-weight-bold text-success">Links úteis</h5>
                            {menuItems.map((item) => (
                                <div key={item.key}>
                                    <p>
                                        <Link to={item.href} className='list-item text-decoration-none text-dark'>{item.title}</Link>
                                    </p>
                                </div>
                            ))}
                        </Col>
                        <Col md='4' lg='3' xl='3' className="mx-auto mt-3">
                            <h5 className="text-uppercase mb-4 font-weight-bold text-success">Contato</h5>
                            {
                                contact.map((item) => (
                                    <Link key={item.key} to={item.href!} target='_blank' rel="noreferrer" className='text-decoration-none text-dark'>
                                        <p>
                                            <item.icon /> {item.text}
                                        </p>
                                    </Link>
                                ))
                            }
                        </Col>
                    </Row>
                    <hr className="mb-4" />
                    <Row className="align-items-center">
                        <Col md='7' lg='8'>
                            <p>
                                <strong className="text-success">Uai Grãos</strong>
                                {' '} &copy;{currentYear} todos os direitos reservados.
                            </p>
                        </Col>
                        <Col md='5' lg='4'>
                            <div className="text-center text-md-right">
                                <ul className="list-unstyled list-inline">
                                    <SocialMediaIcons />
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </div>
    )
}
