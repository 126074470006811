import React from 'react'
import Layout from '../../layouts/DefaultLayout'
import { Col, Container, Row } from 'react-bootstrap'
import './styles.css'

export default function About() {
  return (
    <Layout>
      <Container>
        <Row xs={1} sm={3} className='my-5 d-flex'>
          <Col className='p-2 text-dark bg-opacity-10'>
            <h3 className='text-uppercase text-success mb-3'>Quem Somos</h3>
            <p>
              Atuando no Triângulo Mineiro, Goiás e São Paulo, a Uai Grãos é uma startup especializada na comercialização grãos de soja, sorgo e milho para atender a indústria com agilidade e solidez. Nossa equipe conta com profissionais experientes para realizar a gestão dos processos desde a obtenção das commodities direto do produtor até o fornecimento à indústria, garantindo um trabalho em nível de excelência e com alto grau de confiabilidade.
            </p>
          </Col>
          <Col className='p-2 text-dark bg-opacity-10'>
            <h3 className='text-uppercase text-success mb-3'>O que fazemos</h3>
            <p>
              Para garantir que as melhores commodities cheguem à indústria, nosso trabalho consiste em estabelecer fortes parcerias com os principais produtores do Brasil com o objetivo de obter grãos de qualidade superior a preços mais competitivos. Acompanhamos todos os processos e garantimos a entrega com total eficiência e dentro do prazo.
            </p>
          </Col>
          <Col className='p-2 text-dark bg-opacity-10'>
            <h3 className='text-uppercase text-success mb-3'>Especialistas</h3>
            <p>
              Somos especializados a garantir a entrega da sua produção até no destino final. Temos um Aplicativo pronto para voce realizar seus negócios de forma segura e confiável para atingir a maior gama de clientes possíveis com um preço a altura do seu esforço para realizar a sua produção. Auxiliamos desde a colheita a entrega. Temos também a opção de barter para planejamento do seu plantio.
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
