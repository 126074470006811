import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import { DefaultLayout as DefaultLayoutProps } from '../../@types/DefaultLayout'
import Footer from '../../components/Footer'
import avatarUaiGraos from '../../assets/avatarUaiGraos.png'
import HeaderTicker from '../../components/HeaderTicker'

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children }) => {
    return (
        <div>
            <FloatingWhatsApp
                phoneNumber='5534996423233'
                accountName='Uai Grãos'
                chatMessage='Seja bem vindo! Se você tiver alguma dúvida ou precisar de ajuda enquanto navega pelo nosso sistema, não se preocupe! Estamos aqui para ajudar.'
                placeholder='Digite uma mensagem'
                avatar={avatarUaiGraos}
                statusMessage='Seja bem vindo!'
                chatboxHeight={380}
            />
            <HeaderTicker/>
            <main>
                {children}
            </main>
            <Footer />
        </div>

    )
}

export default DefaultLayout;