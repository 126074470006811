import React from 'react'
import Layout from '../../layouts/DefaultLayout'
import { Col, Container, Row } from 'react-bootstrap'
import sindicatoLogo from '../../assets/sindicatoLogo.png'
import granfertilleLogo from '../../assets/granfertilleLogo.png'
import { Partners as PartnersProps } from '../../@types/Partners'
import { Link } from 'react-router-dom'

const partnersData: PartnersProps[] =
[
  {
      key: '1',
      image: granfertilleLogo,
      href: 'https://granfertilleagropecuaria.com.br/',
      alt: 'Logo da Granfertille',
  },
  {
      key: '2',
      image: sindicatoLogo,
      href: 'http://www.portalituiutaba.com.br/?sec=sipri',
      alt: 'Logo do sindicato de ituiutaba',
  },
]


export default function Partners() {
  return (
    <Layout>
      <Container className='my-5'>
        <div className='mt-5'>
          <p className='text-center text-success text-uppercase fs-3 fw-bold'>Parceiros & Clientes</p>
          <p className='text-center fw-medium fs-5'>Empresas parceiras que depositam total confiança em nossa atuação</p>
        </div>
        <Row xs={1} sm={3} className='d-flex justify-content-center'>
          {partnersData.map((item) => (
            <Col key={item.key} className='mt-3'>
              <Link to={item.href} target='_blank' rel="noreferrer">
                <img src={item.image} alt={item.alt} className='img-thumbnail' />
              </Link>
            </Col>
          ))}
        </Row>
      </Container>

    </Layout>
  )
}

export { partnersData }