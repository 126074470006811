import { Navigation, Pagination, Autoplay, Scrollbar } from 'swiper/modules'
import { SwiperSlide, Swiper } from 'swiper/react'
import InfoCard from '../InfoCard'
import appImg from '../../assets/app_photo.jpg'
import appImg2 from '../../assets/app_photo2.jpg'
import barterImg from '../../assets/barter_photo.jpg'
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import 'swiper/css';
import './styles.css'

export default function CarouselSlider() {
    return (
        <Swiper
            modules={[Navigation, Pagination, Autoplay, Scrollbar]}
            pagination={{ clickable: true }}
            navigation
            autoplay={{ delay: 2000 }}
            scrollbar={{ draggable: true }}
            slidesPerView={1}
            loop
        >
            <SwiperSlide>
                <InfoCard
                    image={appImg2}
                    altImage='Aplicativo Uai Grãos'
                    description='Com o aplicativo da Uai Grãos, o produtor e o comprador têm maior confiabilidade na hora da negociação. Sistema robusto, seguro, completo, ágil e simples para realizar a transação e comercialização da produção de grãos. Temos a ferramenta de Barter para integrar a Indústria diretamente com os produtores, tirando todos os atravessadores.'
                    title='App Uai Grãos'
                    reverseOrder={false}
                    subTitle='Facilitando a negociação entre produtores e compradores'
                    bgColor='success-subtle'
                    color='success'
                />
            </SwiperSlide>
            <SwiperSlide className='carousel-image'>
                <InfoCard
                    image={barterImg}
                    altImage='Aplicativo Uai Grãos'
                    description='Com a ferramenta de Barter (troca) disponibilizada por meio de nosso inovador aplicativo, estamos transformando a maneira como a indústria e os agricultores interagem e colaboram. Eliminando os intermediários, nosso sistema permite que a indústria se comunique diretamente com os produtores, estabelecendo acordos vantajosos para ambas as partes. Apresentamos uma solução que não apenas agiliza a cadeia de suprimentos agrícolas, mas também fortalece os laços entre a indústria e os agricultores, promovendo um sistema mais justo e colaborativo. Baixe já nosso aplicativo e faça parte dessa transformação no setor agrícola. '
                    title='Diferencial'
                    reverseOrder={true}
                    subTitle='Revolucionando a conexão entre indústria e agricultores'
                    bgColor='info-subtle'
                    color='primary'
                />
            </SwiperSlide>
            <SwiperSlide className='carousel-image'>
                <InfoCard
                    image={appImg}
                    altImage='Aplicativo Uai Grãos'
                    description='Na vanguarda da qualidade e inovação, nossa missão é superar todas as expectativas. Contamos com uma equipe altamente capacitada, que atua como o pilar da transparência e eficiência em todas as etapas de nossos serviços. Nossa paixão é criar laços sólidos entre produtores e compradores, pavimentando o caminho para negócios grandiosos e oportunidades extraordinárias. Em um mundo em constante evolução, estamos aqui para impulsionar o crescimento, proporcionando soluções que fazem a diferença.'
                    title='Unindo Produtores e Compradores'
                    reverseOrder={false}
                    subTitle='Entregando qualidade, transparência e eficiência'
                    bgColor='dark-subtle'
                    color='dark'
                />
                    
                
            </SwiperSlide>
        </Swiper>
    )
}