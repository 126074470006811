import React from 'react'
import Layout from '../../layouts/DefaultLayout'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { FaEnvelope, FaHome, FaPhone } from 'react-icons/fa';
import { Contact as ContactProps } from '../../@types/Contact';
import { Link } from 'react-router-dom';

export default function Contact() {
  const contact: ContactProps[] = [
    {
      key: '1',
      icon: FaHome,
      text: ' Avenida Maria Silva Garcia, 575 - Sala 808, Bairro Granja Marileusa, Uberlândia - MG, CEP: 38406-634.',
      href: 'https://maps.app.goo.gl/zjy4BTC9t7tD4FSi8'
    },
    {
      key: '2',
      icon: FaPhone,
      text: '(34) 99642-3233',
      href: 'whatsapp://send?text=Olá estou tendo algumas dúvidas, poderia me ajudar?&phone=5534996423233'
    },
    {
      key: '3',
      icon: FaEnvelope,
      text: 'contato@uaigraos.com.br',
      href: 'mailto:contato@uaigraos.com.br'
    }
  ]
  return (
    <Layout>
      <Container>
        <Row>
          <Col>
            <div className='d-flex flex-column align-items-start mt-5'>
              {
                contact.map((item) => (
                  <Link key={item.key} to={item.href!} target='_blank' rel="noreferrer" className='text-decoration-none text-dark'>
                    <p key={item.key}>
                      <item.icon /> {item.text}
                    </p>
                  </Link>
                ))
              }
            </div>
          </Col>
        </Row>
        <Form action="mailto:contato@uaigraos.com.br" method="post" className='my-5'>
          <Row className='mb-3'>
            <Form.Group as={Col} controlId='name' className="mb-3">
              <Form.Label>Nome:</Form.Label>
              <Form.Control type="text" placeholder="Nome:" name='name' required />
            </Form.Group>
            <Form.Group as={Col} controlId='email'>
              <Form.Label>E-Mail:</Form.Label>
              <Form.Control type="email" placeholder="E-Mail:" name='email' required />
            </Form.Group>
          </Row>
          <Form.Group controlId='message' className='mb-3'>
            <Form.Label>Mensagem:</Form.Label>
            <Form.Control placeholder='Mensagem:' name="message" as='textarea' rows={3} required></Form.Control>
          </Form.Group>
          <Button variant="success" type="submit" className='me-3'>
            Enviar
          </Button>
          <Button variant="danger" type="reset">
            Limpar
          </Button>

        </Form>

      </Container>
    </Layout>
  )
}
