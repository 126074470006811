import { useEffect, useState } from 'react'
import Layout from '../../layouts/DefaultLayout'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import NewsCard from '../../components/NewsCard'
import useNews from '../../core/hooks/useNews'

export default function News() {
  const [loading, setLoading] = useState<boolean>(true)
  const {news, fetchNews} = useNews()

  useEffect(() => {
    fetchNews()
    setLoading(false)
  }, [fetchNews]);

  return (
    <Layout>
      <Container>
        <p className='text-center text-success text-uppercase fs-3 fw-bold mt-5'>
          Notícias
        </p>
        <p className='text-center fw-medium fs-5'>
          Mantenha-se atualizado sobre as principais notícias do setor agrícola seguindo a Uai Grãos em todas as redes sociais!
        </p>

        <Row xs={1} sm={3} className={`my-5 ${loading ? 'd-flex justify-content-center' : ''}`} >
          {loading ? <Spinner animation="border" role="status" variant="success" className='mt-5'>
            <span className="visually-hidden">Carregando notícias...</span>
          </Spinner> :
            news?.data?.map((item) =>
              <Col key={item.id}>
                <NewsCard
                  media_type={item.media_type}
                  caption={item.caption}
                  id={item.id}
                  key={item.id}
                  media_url={item.media_url}
                  permalink={item.permalink}
                  timestamp={item.timestamp}
                  username={item.username}
                  thumbnail_url={item.thumbnail_url}
                />
              </Col>
            )
          }
        </Row>
      </Container>
    </Layout>
  )
}
