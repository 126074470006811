import React from 'react'
import Layout from '../../layouts/DefaultLayout'
import { Container } from 'react-bootstrap'
import './styles.css'


export default function NotFound() {
  return (
    <Layout>
        <Container fluid className='not-found-container'>
       
        <div className='not-found-content'>
          <h1>404 - Página não encontrada</h1>
          <p>A página que você está procurando não foi encontrada.</p>
        </div>
      </Container>
    </Layout>
  )
}
